import { FC, useEffect, useRef, useState } from 'react'
import styles from 'components/Checkout2023/Selections/AddressForm/AddressForm.module.scss'
import checkoutStyles from 'components/Checkout2023/Checkout.module.scss'
import { AddressElement } from '@stripe/react-stripe-js'
import { Currency } from 'prices/types'
import { StripeAddressElementChangeEvent, StripeAddressElementOptions } from '@stripe/stripe-js'
import { amClickedButton, amEnterShippingAddressInCheckout } from 'events/amplitude'
import { stripPhone, validatePhone } from 'utils/validators'
import { trackSubscribeViaSMSCap } from 'events/index'
import { Checkbox } from 'components/Checkout2023/Checkbox'
import { FullStripeAddress } from 'stripe_lib/types'
export interface AddressFormProps {
	currency: Currency
	onComplete: (address: FullStripeAddress) => void
	smsSubscribe: boolean
	setSMSSubscribe: (newValue: boolean) => void
}

export const getAllowedCountriesFromCurrency = (currency: Currency) => {
	switch (currency) {
		case 'USD':
			return ['US']
		case 'CAD':
			return ['CA']
		case 'GBP':
			return ['GB']
		case 'EUR':
			return ['BG', 'HR', 'CZ', 'EE', 'HU', 'IE', 'LV', 'LT', 'LU', 'PL', 'PT', 'RO', 'SK', 'SI', 'DE', 'MC', 'FR', 'IT', 'ES', 'NL', 'BE', 'FI', 'AT']
		case 'AUD':
			return ['AU']
		case 'SEK':
			return ['SE']
		case 'DKK':
			return ['DK']
		case 'AED':
			return ['AE']
		case 'CHF':
			return ['CH']
		case 'MXN':
			return ['MX']
		default:
			return ['US']
	}
}

export const AddressForm: FC<AddressFormProps> = (props) => {
	const [fields, setFields] = useState({
		name: '',
		line1: '',
		city: '',
		postal_code: '',
		country: '',
	})

	const automated_test = typeof window !== 'undefined' && (window.location.search.includes('automated=true') || sessionStorage.getItem('automated') === 'true')

	const shippingOptions: StripeAddressElementOptions = {
		mode: 'shipping',
		fields: { phone: 'always' },
		validation: { phone: { required: 'always' } },
		allowedCountries: getAllowedCountriesFromCurrency(props.currency),
		autocomplete: automated_test
			? undefined
			: {
					mode: 'google_maps_api',
					apiKey: 'AIzaSyA1Fu6LoUVy5Wx9XoBJbV-ibESfAdUY4wk',
			  },
	}

	const [showSMSCheckbox, setShowSMSCheckbox] = useState<boolean>(false)
	const checkboxRef = useRef<HTMLDivElement>(null)

	const handleChange = (e: StripeAddressElementChangeEvent) => {
		if (!e) return

		const { complete, value } = e
		setShowSMSCheckbox(!!value.phone)

		if (
			value.name &&
			value.address.line1 &&
			value.address.city &&
			value.address.postal_code &&
			value.address.country &&
			(fields.line1 !== value.address.line1 ||
				fields.city !== value.address.city ||
				fields.postal_code !== value.address.postal_code ||
				fields.country !== value.address.country ||
				fields.name !== value.name)
		) {
			props.onComplete(value)
			setFields({
				line1: value.address.line1,
				city: value.address.city,
				postal_code: value.address.postal_code,
				country: value.address.country,
				name: value.name,
			})
		}

		if (complete) {
			amEnterShippingAddressInCheckout(value)

			const phone = value.phone

			// localStorage.setItem('phone', phone)

			if (phone) {
				const strippedPhone = stripPhone(phone)
				const passesValidation = validatePhone(strippedPhone)

				const properties = {
					email: localStorage.getItem('customer_email') || localStorage.getItem('email') || '',
					phone: '+1' + strippedPhone,
					emailCapType: `Address Form Checkout`,
					location: 'Checkout',
					captureLocation: 'Checkout',
				}

				if (passesValidation) {
					trackSubscribeViaSMSCap(properties)
					props.onComplete(value)
				} else {
					console.error('Wrong phone number format')
				}
			}
		}
	}

	useEffect(() => {
		handleChange(null)
	}, [])

	return (
		<div className={styles.container}>
			<h2 className={checkoutStyles.section_header}>Shipping address</h2>
			<AddressElement
				options={shippingOptions}
				onChange={handleChange}
			/>
			<div
				ref={checkboxRef}
				style={{ maxHeight: showSMSCheckbox ? `${checkboxRef?.current?.clientHeight || 32}px` : 0, overflow: 'hidden' }}
			>
				<Checkbox
					checked={props.smsSubscribe}
					onChange={(e) => {
						amClickedButton('sms-subscription', 'Checkout')
						props.setSMSSubscribe(e)
					}}
					text={'Text me with news and offers'}
					id={'shipping-address-sms'}
				/>
			</div>
		</div>
	)
}
