import { FC, ReactNode, useEffect, useState } from 'react'
import styles from 'components/Checkout2023/Selections/PaymentTypeSelection/PaymentTypeSelection.module.scss'
import checkoutStyles from 'components/Checkout2023/Checkout.module.scss'
import cx from 'classnames'
import { PaymentMethodID } from 'components/Checkout2023/types'
import { ExpressCheckoutElement, useStripe } from '@stripe/react-stripe-js'
import Stripe from 'stripe'
import { AffirmLogo } from 'components/_const_assets/affirmLogo'
import { ClickResolveDetails, StripeExpressCheckoutElementConfirmEvent, StripeExpressCheckoutElementShippingAddressChangeEvent } from '@stripe/stripe-js'
import { calculateMinMaxDays, getLineItemAmount, stateIsNotAllowed, invoiceContainsSleepElixir } from 'components/Checkout2023/utils'
import { KlarnaLogo } from 'components/_const_assets/klarnaLogo'
import { TransactionModel } from 'avatax/lib/models'
import { amClickedButton } from 'events/amplitude'
import { TruemedLogo } from 'components/_const_assets/truemedLogo'
import { Currency } from 'prices/types'
import { getAllowedCountriesFromCurrency } from '../AddressForm'

export interface PaymentTypeSelectionProps {
	invoice: Stripe.UpcomingInvoice
	selectedPaymentMethod?: PaymentMethodID
	onSelectPaymentMethod?: (paymentMethod: PaymentMethodID) => Promise<Stripe.UpcomingInvoice>
	handleCheckout: (paymentMethod: PaymentMethodID, eventData?: StripeExpressCheckoutElementConfirmEvent) => void
	updateTaxObject: (taxObject: TransactionModel) => void
	taxObject: TransactionModel
	isLoading: boolean
	currency: Currency
}

export interface PaymentMethod {
	id: PaymentMethodID
	name: string
	icon?: string
	payWith?: boolean
	financeWith?: boolean
}

export const PaymentTypeSelection: FC<PaymentTypeSelectionProps> = (props) => {
	const stripe = useStripe()

	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([creditCard])

	const [expressMethods, setExpressMethods] = useState({
		applePay: false,
		googlePay: false,
	})

	useEffect(() => {
		getValidPaymentMethods(props.invoice).then((methods) => {
			setPaymentMethods(methods)
		})
	}, [stripe, props.invoice])

	const handleExpressCheckoutConfirm = (e: StripeExpressCheckoutElementConfirmEvent) => {
		switch (e.expressPaymentType) {
			case 'apple_pay':
				props.handleCheckout('apple-pay', e)
				break
			case 'google_pay':
				props.handleCheckout('google-pay', e)
				break
			default:
				return
		}
	}

	const shippingMinMax = calculateMinMaxDays(props.invoice)

	const showExpressCheckout = expressMethods && (expressMethods.applePay || expressMethods.googlePay)

	const handleShippingAddressChange = (e: StripeExpressCheckoutElementShippingAddressChangeEvent) => {
		const allowedCountries = getAllowedCountriesFromCurrency(props.currency)
		if (e.address.country && !allowedCountries.includes(e.address.country)) {
			e.reject()
		} else if (e.address.state && stateIsNotAllowed(e.address.state)) {
			e.reject()
		} else {
			e.resolve()
		}
	}

	return (
		<div className={styles.container}>
			{props.isLoading && <div className={styles.blocker}></div>}
			<h2 className={checkoutStyles.section_header}>Payment method</h2>
			<div className={styles.express_checkout}>
				<ExpressCheckoutElement
					onConfirm={handleExpressCheckoutConfirm}
					onClick={({ resolve, expressPaymentType }) => {
						const options: ClickResolveDetails = {
							emailRequired: true,
							phoneNumberRequired: true,
							shippingAddressRequired: true,
							shippingRates: [
								{
									id: 'free-shipping',
									displayName: 'Free Shipping',
									amount: 0,
									deliveryEstimate: {
										maximum: {
											unit: 'day',
											value: shippingMinMax.maxDays || 7,
										},
										minimum: {
											unit: 'day',
											value: shippingMinMax.minDays || 5,
										},
									},
								},
							],
							lineItems: props.invoice.lines.data.map((line) => {
								return {
									amount: getLineItemAmount(line, props.taxObject),
									name: (line.price.product as Stripe.Product).name,
								}
							}),
						}

						amClickedButton('express-checkout', expressPaymentType)

						switch (expressPaymentType) {
							case 'apple_pay':
								props.onSelectPaymentMethod('apple-pay')
								break
							case 'google_pay':
								props.onSelectPaymentMethod('google-pay')
						}

						resolve(options)
					}}
					onShippingAddressChange={handleShippingAddressChange}
					options={{
						paymentMethods: {
							applePay: 'auto',
							googlePay: 'auto',
							link: 'never',
						},

						layout: {
							maxColumns: 1,
						},
					}}
					onReady={(e) => {
						setExpressMethods(e.availablePaymentMethods)
					}}
				/>
			</div>
			{showExpressCheckout && (
				<div className={styles.divider}>
					<div />
					<p>or</p>
				</div>
			)}
			<fieldset className={styles.methods}>
				<legend className={'vh'}>Select a payment type</legend>
				{paymentMethods.map((method) => {
					const selected = props.selectedPaymentMethod === method.id

					let buttonContent: ReactNode = ''
					switch (method.id) {
						case 'credit-card': {
							buttonContent = <>Pay with Credit Card</>
							break
						}
						case 'affirm': {
							buttonContent = (
								<>
									Pay with <AffirmLogo />
								</>
							)
							break
						}
						case 'truemed': {
							buttonContent = (
								<span className={styles.truemed_logo}>
									Pay with HSA/FSA <TruemedLogo />
								</span>
							)
							break
						}
						case 'klarna': {
							buttonContent = (
								<>
									Pay with <KlarnaLogo color={'black'} />
								</>
							)
							break
						}
					}

					return (
						<div key={`payment-type-container-${method.id}`}>
							<input
								type={'radio'}
								id={method.id}
								name={method.id}
								value={method.id}
								checked={selected}
								className={'vh'}
								onChange={(e) => {
									if (e.target.checked && props.onSelectPaymentMethod) {
										props.onSelectPaymentMethod(method.id)
									}
								}}
							/>
							<label
								htmlFor={method.id}
								className={cx(styles.radio_label, { [styles.radio_label_checked]: selected })}
							>
								<span className={cx(styles.bubble, { [styles.bubble_filled]: selected })} />
								<span>{buttonContent}</span>
							</label>
						</div>
					)
				})}
			</fieldset>
		</div>
	)
}

const creditCard: PaymentMethod = {
	id: 'credit-card',
	name: 'Credit Card',
}

const affirm: PaymentMethod = {
	id: 'affirm',
	name: 'Affirm',
	financeWith: true,
}

const klarna: PaymentMethod = {
	id: 'klarna',
	name: 'Klarna',
	financeWith: true,
}

const truemed: PaymentMethod = {
	id: 'truemed',
	name: 'True MED',
	financeWith: true,
}

const getValidPaymentMethods = async (invoice: Stripe.UpcomingInvoice): Promise<PaymentMethod[]> => {
	if (!invoice || invoice.total === 0) return [creditCard]

	if (invoiceContainsSleepElixir(invoice)) return [creditCard]

	return preSelectablePaymentMethodsForCurrency(invoice.currency)
}

export const preSelectablePaymentMethodsForCurrency = (currency: string): PaymentMethod[] => {
	// Add affirm and klarna to other regions for phase 2

	switch (currency.toLowerCase()) {
		case 'gbp':
		case 'eur':
			return [creditCard, klarna]
		case 'cad':
			return [creditCard, affirm]
		case 'usd':
			return [creditCard, affirm, truemed]
		default:
			return [creditCard]
	}
}
